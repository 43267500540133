import create from "zustand";
import { IUser } from "../types/common";

type Store = {
  authUser: IUser | null;
  globalLoading: boolean;
  breadCrumb: string;
  onboardingStep: number;
  taxIdModalOpen: boolean;
  setAuthUser: (user: IUser | null) => void;
  setGlobalLoading: (isLoading: boolean) => void;
  setBreadCrumb: (breadCrumb: string) => void;
  setOnboardingStep: (step: number) => void;
  setTaxIdModalOpen: (taxIdModal: boolean) => void;
};

const useStore = create<Store>((set) => ({
  authUser         : null,
  globalLoading    : false,
  breadCrumb       : "",
  onboardingStep   : 0,
  taxIdModalOpen   : false,
  setAuthUser      : (user) => set((state) => ({ ...state, authUser: user })),
  setGlobalLoading : (isLoading) =>
    set((state) => ({ ...state, globalLoading: isLoading })),
  setBreadCrumb     : (breadCrumb) => set((state) => ({ ...state, breadCrumb: breadCrumb })),
  setOnboardingStep : (step) => set((state) => ({ ...state, onboardingStep: step })),
  setTaxIdModalOpen : (taxIdModal) => set((state) => ({ ...state, taxIdModalOpen: taxIdModal }))
}));

export default useStore;
